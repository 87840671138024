// This component is deprecated. Please use instead:
// ```
// import Alert from 'kiss/app/alerts/components/alert'
// ```
import React from 'react'
import PropTypes from 'prop-types'
import { Alert, parseHtml } from '@kisskissbankbank/kitten'

export const KissAlert = ({
  closeButtonLabel = 'Close',
  message = '',
  type = '',
  style = null,
  status,
}) => {
  const statusType = (() => {
    switch (status) {
      case 'danger':
        return type === 'alert'
      case 'success':
        return type === 'notice'
      default:
        return null
    }
  })()

  return (
    <Alert
      status={statusType}
      closeButtonLabel={closeButtonLabel}
      closeButton
      style={style}
    >
      {parseHtml(message)}
    </Alert>
  )
}

KissAlert.propTypes = {
  closeButtonLabel: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.oneOf(['alert', 'notice']),
  style: PropTypes.object,
}
