import React from 'react'
import { renderToString } from 'react-dom/server'
import { Provider } from 'react-redux'
import { BrowserRouter, StaticRouter } from 'react-router-dom'
import { ServerStyleSheet } from 'styled-components'
import { TranslationsProvider } from 'kiss/hooks/use-translation'

export const pageGenerator = (WrappedComponent, getStore) => {
  const GeneratedPage = (props, railsContext) => {
    if (railsContext.serverSide) {
      const sheet = new ServerStyleSheet()
      renderToString(
        sheet.collectStyles(
          <Provider store={getStore()}>
            <StaticRouter context={{}} location={railsContext.location}>
              <TranslationsProvider>
                <WrappedComponent
                  location={railsContext.location}
                  search={railsContext.search}
                  {...props}
                />
              </TranslationsProvider>
            </StaticRouter>
          </Provider>,
        ),
      )
      const style = sheet.getStyleElement()

      return (
        <Provider store={getStore()}>
          <StaticRouter context={{}} location={railsContext.location}>
            <TranslationsProvider>
              <WrappedComponent
                location={railsContext.location}
                search={railsContext.search}
                {...props}
              />
              {style}
            </TranslationsProvider>
          </StaticRouter>
        </Provider>
      )
    }

    return (
      <Provider store={getStore()}>
        <BrowserRouter>
          <TranslationsProvider>
            <WrappedComponent
              location={railsContext.location}
              search={railsContext.search}
              {...props}
            />
          </TranslationsProvider>
        </BrowserRouter>
      </Provider>
    )
  }

  return GeneratedPage
}
