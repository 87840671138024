// Views.
import { Displayer as DraftDisplayer } from '@kisskissbankbank/underwood'
import MasqueradeAlert from 'kiss/app/alerts/components/masquerade-alert'

// KissKiss components
import Footer from 'kiss/app/footer'
import Header from 'kiss/app/header'

// Notifications
import { KissAlert } from 'kiss/components/notifications/alert'
import { getKissStore } from 'kiss/store'

// Tracking
import React from 'react'
import ReactOnRails from 'react-on-rails'
import { pageGenerator } from './page'

ReactOnRails.register({
  DraftDisplayer,
  Header: pageGenerator(() => <Header />, getKissStore),
  KissAlert,
  Footer: pageGenerator(Footer, getKissStore),
  MasqueradeAlert: pageGenerator(MasqueradeAlert, getKissStore),
})
